import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mean',
  templateUrl: './mean.component.html',
  styleUrls: ['./mean.component.scss']
})
export class MeanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
