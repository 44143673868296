import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';


@NgModule({
  providers : [HttpClient],
  imports: [
    CommonModule,
    HttpClient
  ]
})
export class HomeModule { 
  constructor( private http: HttpClient) {
    this.getJSON().subscribe(data => {
      console.log(data)
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get("./assets/blogs.json");
  }
}
