import { Component, OnInit } from '@angular/core';

declare var Module: any;

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit {
  possible: boolean;
  mult_result: number;
  str_len: number;

  constructor() {
    if ('WebAssembly' in window)
    {
      this.possible = true;
    }
    else
    {
      this.possible = false;
    }
  }

  multiply(a: number, b: number): void{
    /*WebAssembly.instantiateStreaming(fetch('assets/scripts/main.wasm')
      .then(result => this.mult_result = result.instance.exports._multiply(a, b))
    );*/
  }
  getLength(s: string): void{
    this.str_len = Module.ccall( 'get_len', 'number', ['string'], [s]);
  }

  ngOnInit() {
  }

}
