import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@NgModule({
  declarations: [],
  providers : [HttpClient],
  imports: [
    CommonModule,
    HttpClient
  ]
})
export class CharModule { 
  constructor( private http: HttpClient) {
    this.getJSON().subscribe( data => {
      console.log(data)
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get("./assets/chars.json");
  }
}
