import { Component, OnInit } from '@angular/core';
import { CharModule } from './char.module'

@Component({
  selector: 'app-char',
  providers: [CharModule],
  templateUrl: './char.component.html',
  styleUrls: ['./char.component.scss']
})
export class CharComponent implements OnInit {
  characters: string[];
  index: number;
  latest: number;
  constructor(
    private appSettingService : CharModule
  ) { }

  ngOnInit() {
    this.appSettingService.getJSON().subscribe(data => {
      this.characters = data as string[];
      this.latest     = this.characters.length - 1;
      this.index      = this.latest;
    });
  }

}
