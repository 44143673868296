import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('collapse', [
      state('open', style({
        opacity: '1',
        display: 'block',
        transform: 'translate3d(0,0,0)'
      })),
      state('closed', style({
        opacity: '0',
        display: 'none',
        transform: 'translate3d(0,-150%,0)'
      })),
      transition('closed => open', [animate('0.2s', style({ display: 'block'}))]),
      transition('open => closed', [animate('0.1s', style({ display: 'block'}))])
    ])
  ]
})
export class AppComponent {
  navbarOpen = false;
  collapse:string = "closed";
  constructor()
  {
    if (window.innerWidth >= 767)
    {
      this.collapse = "open";
    }
  }

  toggleNavbar()
  {
    this.collapse = this.collapse == "open"? 'closed' : 'open';
  }
}
