import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
//routing links
import { HomeComponent } from './home/home.component';
import { WebcomicComponent } from './webcomic/webcomic.component';
import { CharComponent } from './char/char.component';
import { PlaygroundComponent } from './playground/playground.component';
import { MeanComponent } from './mean/mean.component';
const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'about',
        loadChildren: () => import('./about/about-routing.module').then(mod => mod.AboutRoutingModule)
    },
    {
        path: 'saga',
        loadChildren: () => import('./saga/saga-routing.module').then(mod => mod.SagaRoutingModule)
    },
    {
        path: 'char',
        component: CharComponent
    },
    {
        path: 'pg',
        component: PlaygroundComponent
    },
    {
        path: 'spectre',
        loadChildren: () => import('./spectre/spectre-routing.module').then(mod => mod.SpectreRoutingModule)
    },
    {
        path: 'dance',
        loadChildren: () => import('./dance/dance-routing.module').then(mod => mod.DanceRoutingModule)
    },
    {
        path: 'tabs',
        loadChildren: () => import('./tabs/tabs-routing.module').then(mod => mod.TabsRoutingModule)
    }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WebcomicComponent,
    CharComponent,
    PlaygroundComponent,
    MeanComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true,
        enableTracing: true
      }
    )
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
