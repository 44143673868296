import { Component, OnInit } from '@angular/core';
import { HomeModule } from './home.module';

@Component({
  selector: 'app-home',
  providers: [HomeModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  blogs: string[];
  blog: string;
  index: number;
  latest: number;
  constructor(
    private appSettingService : HomeModule
  ) { }

  ngOnInit() {
    this.appSettingService.getJSON().subscribe(data => { 
      this.blogs  = data as string[];
      this.latest = this.blogs.length - 1;
      this.index  = this.latest;
      //this.blogs  = this.blogs.reverse();
      this.blog   = this.blogs[this.index];
      console.log(data) 
    });
  }
  load() {
    
  }
}
